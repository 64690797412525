import Vue from "vue"
import App from "./app.vue"
import store from "./initializers/store"

window.eventHub = new Vue()

import { firebase } from "@/initializers/firebase.js"
import "./initializers/luxon.js"

import "@/assets/css/screen.scss"
import router from "./initializers/router"

Vue.config.productionTip = false

let app

firebase.auth().onAuthStateChanged(async (user) => {
  if(!user) {
    await firebase.auth().signInAnonymously()
  }

  if(user) {
    let currentUser = { uid: user.uid }
    store.commit("setCurrentUser", { currentUser: currentUser })
  }

  if(!app) {
    app = new Vue({
      store,
      router,
      render: h => h(App)
    }).$mount("#app")
  }
})
