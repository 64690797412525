<template>
  <div>
    <section class="main position-static d-flex flex-column justify-content-start align-items-center h-auto min-vh-100 w-100">
      <img src="../assets/images/logo-p-800.png" class="logo img-fluid" alt="Dark Tranquillity">

      <div class="py-5" v-if="loading">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div class="container mt-5" v-else>
        <div class="row">
          <div class="col-12 text-center text-white">
            <h1 class="text-black"><strong>WATCH</strong></h1>

            <div class="row" v-if="$store.state.currentCode || testMode">
              <div class="col-12 col-md-8 pr-md-0">
                <div class="embed-responsive embed-responsive-16by9 bg-black text-white redeem" v-if="$store.state.currentCode || testMode">
                  <player class="embed-responsive-item d-flex flex-column align-items-center justify-content-center text-center" :mode="mode"></player>
                </div>
              </div>
              <div class="col-12 col-md-4 pl-md-0">
                <iframe src="https://minnit.chat/DTLive?embed&&nickname=" class="chat" allowTransparency="true"></iframe>
              </div>
            </div>

            <div class="embed-responsive embed-responsive-16by9 bg-black text-white redeem" v-else>
              <div class="embed-responsive-item d-flex flex-column align-items-center justify-content-center text-center">
                <redeem-form></redeem-form>
              </div>
            </div>

            <div class="mt-5 text-black">Don't have a ticket? Go to <a href="https://secure.tickster.com/3m8nbcn2vgjv793" target="_blank">Tickster</a> and buy one.</div>
          </div>
        </div>
      </div>
    </section>

    <section class="faq position-static d-flex flex-column justify-content-start align-items-center h-auto min-vh-100 w-100">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center text-white">
            <h1 class="mb-5">FAQ</h1>

            <div class="mb-4">
              <p class="font-weight-bold mb-0">Q: What is this?</p>
              <p>
                <strong>A:</strong> Dark Tranquillity performing their entire new album from start to finish in one of the most revered theaters in Sweden.<br>
                The livestream will air on Saturday, November 21 at 20:00 CET.
              </p>
            </div>

            <div class="mb-4">
              <p class="font-weight-bold mb-0">Q: I don't have a ticket, where can I get one?</p>
              <p><strong>A:</strong> Tickets are available on <a href="https://secure.tickster.com/3m8nbcn2vgjv793" target="_blank">Tickster</a>. Special t-shirts are also available.</p>
            </div>

            <div class="mb-4">
              <p class="font-weight-bold mb-0">Q: I have bought a ticket, where is my ticket number?</p>
              <p><strong>A:</strong> In the confirmation email you will find a reference number. This is your ticket.</p>
            </div>

            <div class="mb-4">
              <p class="font-weight-bold mb-0">Q: When I try to use my ticket it says "Invalid ticket"?</p>
              <p><strong>A:</strong> It might take a few minutes for the ticket to become active.<br>Also make sure you are using the reference number from your confirmation email.</p>
            </div>

            <div class="mb-4">
              <p class="font-weight-bold mb-0">Q: For how long after the streaming date can I watch the show?</p>
              <p><strong>A:</strong> The livestream will be up until November 30, so you can watch it anytime you want for about a week.</p>
            </div>

            <div class="mb-4">
              <p class="font-weight-bold mb-0">Q: The live player isn't loading for me, what can I do?</p>
              <p><strong>A:</strong> Make sure to disable any ad-blocker you've got installed in your browser so cdn.jwplayer.com isn't getting blocked.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import { firebase } from "@/initializers/firebase"

  import RedeemForm from "@/components/redeem-form.vue"
  import Player from "@/components/player.vue"

  export default {
    name: "home",
    components: { RedeemForm, Player },
    data() {
      return {
        loading: true,
        settings: null,
      }
    },
    computed: {
      testMode() {
        return new URLSearchParams(window.location.search).get("wearejawsandfriends")
      },
      mode() {
        if(this.testMode) { return "test" }
        if(this.settings) { return this.settings.mode }
        return "offline"
      }
    },
    async mounted() {
      await this.$bind("settings", firebase.firestore().collection(`application`).doc(`settings`))
      this.loading = false
    }
  }
</script>

<style lang="scss" scoped>
  .spinner-border {
    width: 5rem;
    height: 5rem;
    border-width: 0.6em;
  }

  .main {
    background-attachment: scroll, fixed;
    background-image: linear-gradient(180deg, rgba(48, 48, 48, 0.9), rgba(0, 0, 0, 0.2) 18%, rgba(0, 0, 0, 0.2) 80%, rgba(0, 0, 0, 0.8)), url("../assets/images/bg.jpg");
    background-position: 0px 0px, 50% 50%;
    background-repeat: repeat, no-repeat;
    background-size: auto, cover;
    overflow: visible;
    padding-bottom: 88px;
    padding-top: 88px;
  }

  .logo {
    width: 90%;
    max-width: 560px;
  }

  .faq {
    background-attachment: scroll, fixed;
    background-color: #000;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../assets/images/bg2.jpg");
    background-position: 0px 0px, 0% 50%;
    background-repeat: repeat, no-repeat;
    background-size: auto, cover;
    overflow: visible;
    padding-bottom: 88px;
    padding-top: 88px;
  }

  .redeem {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('../assets/images/press-photo.jpg');
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
  }

  .chat {
    border: none;
    width: 100%;
    height: 100%;

    @media (max-width: 767.98px) {
      height: 500px;
    }
  }
</style>