<template>
  <main id="app">
    <router-view></router-view>
  </main>
</template>

<script>
  export default {
    name: "app"
  }
</script>